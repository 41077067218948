@import '../../scss/variables';

body.about {
  background-color: $darkBg;
  color: $white;
  #logo {
    .logoOwicz {
      filter: $filterSilver;
    }
  }
  section {
    text-align: center;
  }
  h1, h2 {
    width: 80%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font: 100 6vw/1.1 $font;
    margin: 0;
    text-transform: uppercase;
    white-space: break-spaces;
    @media #{$smallMobile} {
      font-size: 10vw;
      font-weight: 200;
      width: calc(100% - 90px);
      padding: 0 45px;
    }
    span {
      display: block;
    }
  }
  section#slideOne {
    h1 {
      .first, .second {
        opacity: 0;
        transform: scale(0.1);
      }
      .second {
        margin-top: 50px;
        text-transform: none;
        font-size: 4vw;
        @media #{$smallMobile} {
          font-size: 5vw;
          margin-top: 15px;
        }
        @media #{$landscape} {
          margin-top: 20px;
        }
      }
    }

  }
  section#slideTwo {
    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  section#slideFive {
    background-color: $dark;
    #white {
      background-color: $white;
      width: 100%;
      height: 100%;
      h2 {
        color: $dark;
      }
    }
  }
}