@import '../../scss/variables';

#heroSection {
  background: 
  linear-gradient(to bottom,  
    rgba(222,222,222,1) 0%,
    rgba(244,244,244,1) 50%,
    rgba(239,239,239,1) 100%);
  height: 100svh;
  h2 {
    position: absolute;
    left: 50px;
    bottom: 50px;
    display: block;
    font-weight: 100;
    margin: 0;
    font-size: 8vw;
    text-transform: uppercase;
    line-height: 1;
    white-space: pre-line;
    z-index: 2;
    @media #{$desktop} {
      left: 150px;
      bottom: 200px;
    }
    @media #{$lowHeight} {
      bottom: 100px;
    }
    @media #{$mobile} {
      left: 80px;
      font-size: 10vw;
    }
    @media #{$smallMobile} {
      left: 30px;
      font-size: 11vw;
      font-weight: 200;
    }
    @media #{$landscape} {
      left: 60px;
      bottom: 60px;
      font-size: 6vw;
    }
    span {
      display: block;
      margin-bottom: 25px;
      text-transform: none;
      font-style: italic;
      font-weight: 500;
      font-size: 2rem;
      color: $silver;
      @media #{$mobile} {
        font-size: 3vw;
      }
      @media #{$smallMobile} {
        font-size: 5vw;
        margin-bottom: 15px;
      }
      @media #{$landscape} {
        font-size: 2vw;
        margin-bottom: 15px;
      }
    }
  }
  #blob {
    width: 100%;
    height: 100%;
  }
}
@keyframes gradientTransform {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotateBlob {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.desc {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  @media #{$smallDesktop} {
    padding: 0 50px;
  }
  @media #{$mobile} {
    width: calc(100% - 100px);
  }
  @media #{$smallMobile} {
    padding: 0 30px;
    width: calc(100% - 60px);
  }
  @media #{$desktop} {
    width: 60%;
    margin-left: 10%;
  }
  p {
    display: block;
    margin: 0 0 30px 0;
    font-size: 1.68rem;
    line-height: 37px;
    font-weight: 200;
    color: $dark;
    @media #{$smallDesktop} {
      font: 200 1.4rem/1.3 $font;
    }
    @media #{$lowHeight} {
      font: 200 1.4rem/1.3 $font;
    }
    @media #{$mobile} {
      margin-bottom: 15px;
    }
    @media #{$small500} {
      margin-bottom: 10px;
    }
    @media #{$small400} {
      font: 200 1.2rem/1.2 $font;
    }
    @media #{$landscapeSmall} {
      font-size: 1rem;
      margin-bottom: 5px;
    }
  }
}
#futureSite {
  background-color: $darkBg;
  .desc {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @media #{$lowHeight} {
      width: calc(60% - 100px);
      top: auto;
      bottom: 100px;
      transform: translateY(0);
    }
    @media #{$mobile} {
      width: calc(65% - 100px);
    }
    @media #{$smallMobile} {
      width: calc(100% - 60px);
      top: 120px;
      bottom: auto;
      transform: translateY(0);
      p {
        font-weight: 300;
      }
    }
    @media #{$landscape} {
      width: calc(90% - 100px);
      bottom: 30px;
    }
    h2 {
      color: $white;
    }
    p {
      color: $white;
    }
  }
  #futureSiteBg {
    position: absolute;
    right: 150px;
    bottom: 100px;
    @media #{$smallDesktop} {
      max-width: 200px;
      height: auto;
      right: 100px;
      bottom: 50px;
    }
    @media #{$lowHeight} {
      max-width: 200px;
      height: auto;
      right: 100px;
      bottom: 50px;
    }
    @media #{$smallMobile} {
      right: 30px;
      bottom:30px;
      max-width: 120px;
    }
    @media #{$small400} {
      max-width: 100px;
    }
    @media #{$landscape} {
      right: 30px;
      bottom:30px;
      max-width: 120px;
    }
  }
}

.pin-spacer-about {
  padding: 0 !important;
}
#aboutMe {
  .desc {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    @media #{$lowHeight} {
      width: calc(60% - 100px);
      top: auto;
      bottom: 150px;
      transform: translateY(0);
    }
    @media #{$mobile} {
      width: calc(65% - 100px);
    }
    @media #{$smallMobile} {
      width: calc(100% - 60px);
      top: 100px;
      bottom: auto;
      transform: translateY(0);
      p {
        font-weight: 300;
      }
    }
    @media #{$landscape} {
      width: calc(70% - 100px);
      bottom: 30px;
    }
    @media #{$landscapeSmall} {
      width: calc(75% - 100px);
      bottom: 30px;
      padding: 0 30px;
    }
  }
  #images {
    position: absolute;
    right: 50px;
    width: calc(100% - 50px);
    height: 100%;
    z-index: 1;
    @media #{$mobile} {
      right: 0;
      width: 100%;
    }
    @media #{$landscapeSmall} {
      right: 72px;
    }
  }
  #firstImage, #secondImage {
    position: absolute;
    bottom: 0;
    right: 0;
    @media #{$smallDesktop} {
      width: auto;
      height: 80vh;
    }
    @media #{$lowHeight} {
      width: auto;
      height: 80vh;
    }
    @media #{$mobile} {
      height: 55vh;
      right: 0px;
    }
    @media #{$smallMobile} {
      height: 35vh;
    }
    @media #{$landscapeSmall} {
      height: 80vh;
    }
  }
  #firstImage {
    opacity: 1;
  }
  #secondImage {
    opacity: 0;
  }
}

.pin-spacer-langPin {
  background-color: #f8f8f8;
  padding-bottom: 0 !important;
}
#languages {
  background: $white;
  #firstBG, #secondBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100vh;
  }
  #firstBG {
    background: transparent url('../../img/languages-scection-bg.webp') no-repeat center bottom;
    background-size: cover;
    z-index: 2;
    @media #{$smallDesktop} {
      background-position: -90px 100px;
    }
    @media #{$smallMobile} {
      background-position: -150px 100px;
    }
    @media #{$landscape} {
      background-position: 0px 0px;
    }
  }
  #secondBG {
    /*
    * Created with https://www.css-gradient.com
    * Gradient link: https://www.css-gradient.com/?c1=cbbbcb&c2=e6e1e6&gt=l&gd=dcl
    */
    background: #CBBBCB;
    // background: linear-gradient(90deg, #CBBBCB, #E6E1E6);
    background: linear-gradient(45deg, #CBBBCB, #E6E1E6);
  }
  .desc {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    @media #{$desktop} {
      top: 50%;
      transform: translate(calc(-50% + 200px), -50%);
      display: block;
      width: auto;
      margin-left: 0;
    }
    @media #{$smallDesktop} {
      top: 200px;
      align-items: center;
    }
    @media #{$smallMobile} {
      text-align: center;
      top: 110px;
      transform: translateX(-50%);
    }
    @media #{$landscape} {
      top: 150px;
      transform: translateX(calc(-50% + 150px));
    }
    @media #{$landscapeSmall} {
      top: 110px;
      transform: translateX(calc(-50% + 120px));
    }
    h3 {
      margin: 0;
      line-height: 1;
    }
    .question {
      font-size: 6vw;
      font-weight: 100;
      white-space: break-spaces;
      text-transform: uppercase;
      @media #{$desktop} {
        font-size: 6.125rem;
      }
      @media #{$lowHeight} {
        font-size: 4rem;
      }
      @media #{$smallMobile} {
        font-size: 7vw;
        font-weight: 300;
        line-height: 1.2;
      }
      @media #{$landscapeSmall} {
        font-size: 5vw;
        font-weight: 200;
      }
    }
    .answer {
      font-size: 2rem;
      font-weight: 500;
      font-style: italic;
      color: $silver;
      margin-top: 30px;
      @media #{$smallMobile} {
        font-size: 5vw;
        margin-top: 15px;
      }
      @media #{$landscapeSmall} {
        font-size: 3vw;
      }
    }
  }
}

#competencies {
  background-color: #f8f8f8;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  @media #{$desktop} {
    padding: 100px 150px;
    width: calc(100% - 300px);
    height: calc(100svh - 200px);
  }
  @media #{$mobile} {
    padding: 0;
  }
  .title {
    padding-bottom: 50px;
    @media #{$smallDesktop} {
      padding: 0 50px;
    }
    @media #{$lowHeight} {
      padding-bottom: 30px;
    }
    @media #{$smallDesktop} {
      padding: 0 30px;
      .subTitle {
        font-size: 1rem;
      }
    }
    @media #{$small400} {
      .subTitle {
        font-size: 4vw;
      }
    }
    @media #{$landscape} {
      margin-top: 20px;
      padding-left: 160px;
    }
    @media #{$landscapeSmall} {
      margin-top: 0;
      padding-left: 120px;
    }
  }
  .desc {
    flex-direction: row;
    @media #{$smallDesktop} {
      gap: 30px;
    }
    @media #{$mobile} {
      flex-direction: column;
      gap: 15px;
    }
    @media #{$landscapeSmall} {
      width: calc(100% - 60px);
      flex-direction: row;
      gap: 15px;
      padding: 0 30px;
    }
    p {
      position: relative;
      display: block;
      font-size: 1.1rem;
      line-height: 1.3;
      @media #{$smallDesktop} {
        width: 33.333%;
      }
      @media #{$mobile} {
        width: 100%;
        margin-bottom: 0;
      }
      @media #{$landscapeSmall} {
        font-size: 0.84rem;
        line-height: 1.1;
      }
      @media #{$desktop} {
        width: 33.333%;
        font-size: 1.375rem;
        line-height: 1.5;
        &::before {
          content: "";
          position: absolute;
          top: 10px;
          display: block;
          width: 3.9px;
          height: 51.5px;
          background-color: $silver;
          left: -40px;
        }
      }
      @media #{$lowHeight} {
        font-size: 1rem;
      }
    }
    @media #{$desktop} {
      gap: 75px;
      width: 100%;
      margin-left: 0;
    }
  }
  @media #{$smallMobile} {
    .content {
      position: absolute;
      top: 100px;
      z-index: 2;
      .desc{
        gap: 10px;
        p {
          font-size: 1rem;
          font-weight: 300;
        }
      }
    }
  }
  @media #{$small400} {
    .content {
      .desc {
        p {
          font-size: .85rem;
          // line-height: 1.2;
        }
      }
    }
  }
  #competenciesSiteBg {
    position: absolute;
    right: 150px;
    bottom: 100px;
    @media #{$smallDesktop} {
      max-width: 200px;
      height: auto;
      right: 100px;
      bottom: 50px;
    }
    @media #{$lowHeight} {
      max-width: 200px;
      height: auto;
      right: 100px;
      bottom: 50px;
    }
    @media #{$mobile} {
      right: 50px;
      bottom: 50px;
    }
    @media #{$smallDesktop} {
      right: 30px;
      bottom: 30px;
      max-width: 120px;
    }
    @media #{$small400} {
      max-width: 100px;
    }
  }
}

#workSection {
  background-color: $white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: auto;
  padding: 100px 0 300px 0;
  @media #{$smallDesktop} {
    padding-bottom: 180px;
  }
  @media #{$lowHeight} {
    padding: 100px 0 180px;
  }
  @media #{$smallMobile} {
    padding: 100px 0 150px;
  }
  @media #{$landscapeSmall} {
    padding: 100px 0 120px;
  }
  .desc {
    width: calc(100% - 100px);
    @media #{$desktop} {
      margin: 0 150px;
      width: calc(100% - 300px);
    }
    @media #{$smallMobile} {
      width: calc(100% - 60px);
    }
  }
  .more {
    @media #{$landscape} {
      right: auto;
      top: auto;
      left: 50px;
      bottom: 50px;
    }
    @media #{$landscapeSmall} {
      font-size: 0.9rem;
    }
  }
}