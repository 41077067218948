#toggle {
  &::before {
    content: '';
    display: block;
    width: 100px;
    height: 14px;
    background-image: url('../img/toggle.svg');
    filter: $filterDark;
    filter: invert(50%) brightness(110%);
  }
}
#navBarToggle.close i#toggle {
  &::before {
    width: 55px;
    height: 55px;
    background-image: url('../img/close.svg');
    @media #{$smallMobile} {
      width: 50px;
      height: 50px;
    }
    @media #{$lowHeight} {
      width: 40px;
      height: 40px;
    }
  }
}