@import '../../scss/variables';

body.afterWork {
  background-color: $darkBg;
  #logo {
    .logoSignet, .logoOwicz, .logoCom {
      filter: $filterWhite;
    }
  }
  #logo.navOpen {
    .logoSignet, .logoOwicz, .logoCom {
      filter: $filterSilver;
    }
  }
  #toggle::before {
    filter: $filterWhite;
  }
  #navBarToggle.close #toggle::before {
    filter: $filterSilver;
  }
  #heroSection {
    background: $darkBg;
    height: 100vh;
    h1.title {
      position: absolute;
      right: 0;
      bottom: 150px;
      color: $dark;
      font: 100 5vw/1.1 $font;
      background-color: rgba($white, .7);
      padding: 20px 60px 20px 40px;
      @media #{$smallDesktop} {
        font-weight: 200;
      }
      @media #{$mobile} {
        font-size: 7vw;
      }
      @media #{$smallMobile} {
        right: 30px;
        transform: rotate(0);
        font: 300 10vw/1.1 $font;
      }
      @media #{$small500} {
      }
      @media #{$landscapeSmall} {
        font-size: 5vw;
        padding: 30px 30px 30px 40px;
        bottom: 50px;
      }
      .subTitle {
        color: $dark;
        font-size: 1.5vw;
        @media #{$mobile} {
          font-size: 2vw;
        }
        @media #{$smallMobile} {
          font-size: 4vw;
        }
      }
      &::after {
        display: none;
      }
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left;
    }
  }
  // .pin-spacer {
  //   padding: 0 !important;
  // }
  #gallery {
    text-align: center;
    h2 {
      display: block;
      width: 40%;
      margin:  auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font: 100 2vw/1.1 $font;
      text-align: center;
      color: $white;
      text-transform: uppercase;
      white-space: pre-wrap;
      @media #{$smallDesktop} {
        font-size: 3vw;
        width: 50%;
      }
      @media #{$mobile} {
        width: 70%;
        font-size: 4vw;
        line-height: 1.3;
      }
      @media #{$smallMobile} {
        font-weight: 200;
        font-size: 7vw;
      }
      @media #{$landscape} {
        font-weight: 200;
        font-size: 4vw;
        width: 50%;
      }
      @media #{$landscapeSmall} {
        font-weight: 200;
        font-size: 3vw;
        width: 60%;
      }
    }
    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  #scroll img {
    filter: $filterWhite;
  }
}