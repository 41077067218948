@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


$font: "Poppins", sans-serif;

$white: #F4F4F4;
$dark: #404040;
$silver: #878787;
$darkBg: #3C3B3A;

$filterWhite: invert(100%) sepia(99%) saturate(41%) hue-rotate(148deg) brightness(114%) contrast(91%);
$filterDark: invert(22%) sepia(0%) saturate(0%) hue-rotate(176deg) brightness(93%) contrast(83%);
$filterSilver: invert(56%) sepia(0%) saturate(0%) hue-rotate(87deg) brightness(95%) contrast(90%);

$landscapeSmall: "(orientation: landscape) and (max-height: 400px)";
$landscape: "(orientation: landscape) and (max-height: 650px)";
$fullHD: "(max-width: 1920px)";
$smallDesktop: "(max-width: 1366px)";
$mobile: "(max-width: 991px)";
$smallMobile: "(max-width: 650px)";
$small500: "(max-width: 500px)";
$small400: "(max-width: 430px)";
$lowHeight: "(max-height: 928px)";
$desktop: "(min-width: 1366px)";