@import '../../scss/variables';

body.privacyPolicy {
  #article {
    width: 80%;
    height: auto;
    margin: 350px auto 100px auto;
    @media #{$smallDesktop} {
      margin-top: 250px;
    }
    @media #{$mobile} {
      margin: 120px 50px;
      width: calc(100% - 100px);
    }
    @media #{$smallMobile} {
      margin: 80px 30px;
      width: calc(100% - 60px);
    }
    @media #{$landscape} {
      margin-top: 200px;
    }
    @media #{$landscapeSmall} {
      margin-top: 80px;
    }
    h1, h2, h3 {
      font: italic 300 1.8rem/1.2 $font;
      margin: 30px 0 10px 0;
      @media #{$mobile} {
        font-size: 1.6rem;
      }
      @media #{$smallMobile} {
        font-size: 1.4rem;
      }
    }
    h1 {
      font-size: 2rem;
      font-weight: 400;
      @media #{$mobile} {
        font-size: 1.8rem;
      }
      @media #{$smallMobile} {
        font-size: 1.6rem;
      }
    }
    a {
      color: $dark;
      text-decoration: underline;
    }
    p {
      font-size: 1.2rem;
      line-height: 1.3;
      font-weight: 200;
      margin: 0;
    }
  }
}