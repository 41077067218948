ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  border: none;
  padding: 0;
  background: transparent;
}

.more {
  position: absolute;
  left: 10%;
  bottom: 150px;
  display: block;
  width: 155px;
  height: 140px;
  font-size: 1rem;
  font-weight: 300;
  color: $dark;
  text-align: center;
  line-height: 120px;
  transition: transform .5s;
  z-index: 5;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: url('../img/button-bg.svg');
    filter: $filterDark;
    width: 155px;
    height: 140px;
    transition: transform .5s ease-out;
  }
  @media #{$desktop} {
    &:hover {
      &::after {
        transform: rotate(15deg) scale(1.08);
      }
    }
  }
  @media #{$smallDesktop} {
    left: 50px;
    bottom: 50px;
    width: 80px;
    height: 72.6px;
    padding: 22px 10px 10px;
    line-height: 1.2;
    text-align: center;
    &::after {
      width: 100px;
      height: 90px;
      transition: transform .5s ease-out
    }
  }
  @media #{$lowHeight} {
    left: 10%;
    bottom: 50px;
    width: 80px;
    height: 72.6px;
    padding: 22px 10px 10px;
    line-height: 1.2;
    text-align: center;
    &::after {
      width: 100px;
      height: 90px;
      transition: transform .5s ease-out
    }
  }
  @media #{$smallMobile} {
    left: 30px;
    bottom: 30px;
    padding-top: 20px;
  }
  @media #{$landscape} {
    top: 100px;
    left: auto;
    right: 30px;
    bottom: auto;
  }
  @media #{$landscapeSmall} {
    top: 80px;
    width: 60px;
    height: auto;
    padding: 15px 10px 10px;
    line-height: 1.2;
    text-align: center;
    &::after {
      width: 80px;
      height: 70px;
      transition: transform .5s ease-out
    }
  }
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font: 400 1rem/1.2 $font;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1 {
  font-weight: 100;
}

#scroll {
  position: fixed;
  right: 100px;
  bottom: 100px;
  z-index: 5;
  @media #{$desktop} {
    cursor: pointer;
  }
  @media #{$smallDesktop} {
    right: 50px;
    bottom: 50px;
  }
  @media #{$smallMobile} {
    right: 30px;
    bottom: 30px;
  }
  @media #{$landscapeSmall} {
    right: 30px;
    bottom: 30px;
  }
  #arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 8px;
    height: 30px;
  }
  img {
    filter: $filterSilver;
  }
}

section {
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#mainNav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $white url('../img/menu-bg.jpg') top center no-repeat;
  background-size: cover;
  z-index: 9;
  #langSwitcher {
    position: absolute;
    top: 50px;
    right: 155px;
    z-index: 11;
    font: 100 3.25rem/1 $font;
    @media #{$desktop} {
      top: 100px;
      right: 205px;
    }
    @media #{$lowHeight} {
      top: 50px;
      right: 155px;
      font-size: 2rem;
    }
    @media #{$smallMobile} {
      top: 30px;
      right: 95px;
    }
    @media #{$landscape} {
      top: 30px;
      right: 100px;
      font-size: 2.5rem;
    }
    button {
      font: 200 2rem/1 $font;
      cursor: pointer;
      color: $silver;
      @media #{$smallMobile} {
        font-size: 3rem;
      }
      @media #{$landscape} {
        font-size: 2.5rem;
      }
    }
    button.activeLang {
      color: $dark;
    }
  }
  #nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar { display: none; }
    @media #{$landscape} {
      margin-top: 20px;
    }
    @media #{$landscapeSmall} {
      margin-top: 60px;
    }
    li {
      text-align: right;
      width: 100%;
      padding: 20px 0;
      border-bottom: 0.4px solid rgba($silver, .2);
      transition: all 0.5s;
      &:hover {
        border-bottom-color: rgba($silver, .6);
      }
      @media #{$small500} {
        text-align: center;
      }
      @media #{$landscape} {
        padding: 15px 0;
        text-align: center;
      }
      &:nth-child(even) {
        text-align: left;
        @media #{$small500} {
          text-align: center;
        }
        @media #{$landscape} {
          text-align: center;
        }
      }
      &:last-child {
        border-bottom: none;
      }
      a {
        display: block;
        width: 100%;
        font-weight: 100;
        font-size: 6rem;
        line-height: 1;
        color: $dark;
        text-transform: uppercase;
        @media #{$fullHD} {
          font-size: 4vw;
        }
        @media #{$lowHeight} {
          font-size: 2.8vw;
          font-weight: 200;
        }
        @media #{$mobile} {
          font-size: 8vw;
        }
        @media #{$smallMobile} {
          font-size: 10vw;
        }
        @media #{$small400} {
          font-size: 10vw;
          font-weight: 200;
        }
        @media #{$landscape} {
          font-size: 4vw;
          font-weight: 100;
        }
        @media #{$landscapeSmall} {
          font-size: 4vw;
          font-weight: 200;
        }
      }
    }
  }
  #social {
    // display: none;
    display: flex;
    gap: 50px;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 50px;
    @media #{$desktop} {
      bottom: 100px;
    }
    @media #{$lowHeight} {
      bottom: 50px;
    }
    @media #{$smallMobile} {
      gap: 30px;
    }
    @media #{$small500} {
      gap: 15px;
    }
    @media #{$small500} {
      gap: 15px;
    }
    @media #{$small400} {
      gap: 10px;
    }
    @media #{$landscape} {
      bottom: auto;
      left: auto;
      top: 35px;
      gap: 30px;
      right: 140px;
      img {
        width: auto;
        height: 30px;
      }
    }
    img {
      filter: $filterSilver;
      @media #{$small400} {
        width: 50px;
        height: auto;
      }
    }
  }
}
#mainNav.open {
  display: flex;
}

header {
  #logo {
    position: fixed;
    top: 50px;
    left: 50px;
    display: block;
    width: 295.9px;
    height: 150px;
    z-index: 11;
    .logoSignet, .logoOwicz, .logoCom {
      position: absolute;
    }
    .logoSignet {
      top: 0;
      right: 0;
      filter: $filterSilver;
      @media #{$lowHeight} {
        right: auto;
        left: 0;
      }
    }
    .logoOwicz {
      top: 79.1px;
      filter: $filterDark;
      @media #{$mobile}, #{$lowHeight} {
        display: none;
      }
    }
    .logoCom {
      top: 114.6px;
      right: 58.3px;
      filter: $filterSilver;
      @media #{$mobile}, #{$lowHeight} {
        display: none;
      }
    }
    @media #{$desktop} {
      top: 100px;
      left: 100px;
    }
    @media #{$lowHeight} {
      top: 50px;
      left: 50px;
    }
    @media #{$mobile} {
      top: 50px;
      left: 50px;
      width: 60px;
      height: 54.5px;
      .logoSignet {
        width: 60px;
        height: 54.5px;
      }
    }
    @media #{$smallMobile} {
      top: 30px;
      left: 30px;
    }
    @media #{$landscape} {
      top: 30px;
      left: 30px;
    }
  }
  .logo-fade-out {
    transition: opacity 0.5s ease-out;
    opacity: 0;
  }
  
  .logo-move-left {
    transition: transform 1s ease-out;
    transform: translateX(-192.8px); /* Przesuń logo w lewo */
    @media #{$mobile}, #{$lowHeight} {
      transform: none;
    }
  }
  #navBarToggle {
    position: fixed;
    top: 50px;
    right: 50px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 11;
    cursor: pointer;
    @media #{$desktop} {
      top: 100px;
      right: 100px;
    }
    @media #{$lowHeight} {
      top: 50px;
      right: 50px;
    }
    @media #{$smallMobile} {
      top: 30px;
      right: 30px;
      width: 60px;
      height: 50px;
    }
    @media #{$landscapeSmall} {
      top: 30px;
      right: 30px;
      height: 43px;
    }
  }
  @media #{$landscape} {
    #navBarToggle.close {
      top: 30px;
      right: 30px;
      width: 40px;
      height: 40px;
      #toggle::before {
        width: 40px;
        height: 40px;
      }
    }
  }
}

.whiteFilter {
  filter: $filterWhite;
}
.noScroll {
  overflow-y: hidden;
}
.open {
  display: block;
}

.title {
  display: block;
  font-weight: 400;
  margin: 0; 
  font-size: 3.43rem;
  text-transform: uppercase;
  line-height: 1;
  white-space: normal;
  @media #{$smallDesktop} {
    font-size: 3rem;
  }
  @media #{$lowHeight} {
    font-size: 2.6rem;
  }
  @media #{$mobile} {
    font-size: 4vw;
  }
  @media #{$smallMobile} {
    font-size: 6vw;
  }
  @media #{$landscape} {
    font-size: 3vw;
  }
  span {
    display: block;
    margin-bottom: 15px;
    text-transform: none;
    font-style: italic;
    font-weight: 500;
    font-size: 1.43rem;
    color: $silver;
    @media #{$smallDesktop} {
      font-size: 1rem;
      margin-bottom: 10px;
    }
    @media #{$mobile} {
      // font-size: 2vw;
    }
  }
  &::after {
    content: '';
    display: block;
    width: 51.5px;
    height: 3.9px;
    background-color: $silver;
    margin: 20px 0 30px 0;
    @media #{$mobile} {
      margin: 5px 0 20px 0;
    }
    @media #{$landscapeSmall} {
      margin-bottom: 15px;
    }
  }
}

@keyframes fillBackgroundLeft {
  0% {
    width: 0;
    left: 100%;
  }
  100% {
    width: 100%;
    left: 0;
  }
}
@keyframes fillBackgroundRight {
  0% {
    width: 0;
    right: 100%;
  }
  100% {
    width: 100%;
    right: 0;
  }
}
#projects {
  width: 100%;
  ul {
    display: flex;
    flex-direction: column;
    @media #{$desktop} {
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    li {
      position: relative;
      overflow: hidden;
      display: block;
      text-align: right;
      width: 100%;
      padding: 20px 0;
      margin: 25px 0;
      border-top: 0.4px solid rgba($silver, .1);
      border-bottom: 0.4px solid rgba($silver, .1);
      @media #{$smallMobile} {
        text-align: center;
      }
      @media #{$lowHeight} {
        text-align: center;
      }
      &:nth-child(even) {
        text-align: left;
        @media #{$smallMobile} {
          text-align: center;
        }
        @media #{$lowHeight} {
          text-align: center;
        }
      }
      @media #{$desktop} {
        z-index: 2;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 100%;
          width: 100%;
          height: 100%;
          background-color: rgba($silver, .05);
          z-index: 1;
          transition: left 0.3s ease;
        }
        &:hover::after {
          left: 0;
          animation: fillBackgroundLeft 0.8s ease;
        }
        &:nth-child(even):hover::after {
          left: auto;
          right: 0;
          animation: fillBackgroundRight 0.8s ease;
        }
      }
      @media #{$smallDesktop} {
        padding: 10px 0;
        margin: 15px 0;
      }
      @media #{$lowHeight} {
        padding: 10px 0;
        margin: 15px 0;
      }
      @media #{$smallMobile} {
        border-color: rgba($silver, .6);
      }
      a {
        display: block;
        width: 100%;
        font-weight: 100;
        font-size: 7.5rem;
        line-height: 1;
        color: $dark;
        text-transform: uppercase;
        @media #{$desktop} {
          position: relative;
          z-index: 3;
        }
        @media #{$smallDesktop} {
          font-size: 5vw;
        }
        @media #{$lowHeight} {
          font-size: 5vw;
        }
        @media #{$smallMobile} {
          font-size: 8vw;
          font-weight: 200;
        }
      }
    }
  }
}

#bottomHooks {
  background-color: $darkBg;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $white;
  .container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 100px;
    @media #{$smallMobile} {
      margin-top: 140px;
    }
  }
  h3 {
    font-size: 3.5vw;
    font-weight: 100;
    text-transform: uppercase;
    line-height: 1.1;
    margin: 0;
    text-align: center;
    white-space: break-spaces;
    @media #{$smallDesktop} {
      font-size: 6vw;
    }
    @media #{$mobile} {
      font-size: 6vw;
    }
    @media #{$smallMobile} {
      font-size: 8vw;
      font-weight: 200;
    }
    @media #{$landscape} {
      font-size: 5vw;
    }
    @media #{$landscapeSmall} {
      font-size: 5vw;
    }
  }
  h4 {
    font-size: 1.6vw;
    font-weight: 300;
    font-style: italic;
    line-height: 1.3;
    margin: 50px 0 0 0;
    min-height: 39.31px;
    @media #{$smallDesktop} {
      font-size: 3vw;
    }
    @media #{$mobile} {
      font-size: 1.5rem;
    }
    @media #{$smallMobile} {
      font-size: 1.2rem;
      line-height: 1.3;
    }
    @media #{$landscape} {
      font-size: 1.3rem;
    }
    @media #{$landscapeSmall} {
      margin-top: 20px;
      font-size: 1.2rem;
    }
  }
  .showProjects {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1;
    padding-top: 45px;
    color: $white;
    &::after {
      filter: $filterWhite
    }
    @media #{$smallDesktop}, #{$lowHeight} {
      padding-top: 25px;
      font-size: .8rem;
      line-height: 1.2;
      font-weight: 700;
    }
    // @media #{$mobile} {
    //   padding-top: 15px;
    //   font-size: .9rem;
    // }
    @media #{$landscape} {
      top: auto;
      right: auto;
      left: 50px;
      bottom: 30px;
    }
    @media #{$landscapeSmall} {
      padding-top: 13px;
      font-size: .7rem;
    }
  }
}
footer {
  background-color: $darkBg;
  display: block;
  overflow: hidden;
  color: rgba($white, .4);
  #bottom {
    width: calc(100% - 200px);
    padding: 40px 0 40px;
    margin: 0 100px;
    border-top: 0.4px solid rgba($white, .4);
    display: flex;
    justify-content: space-between;
    @media #{$smallDesktop} {
      width: calc(100% - 100px);
      margin: 0 50px;
    }
    @media #{$smallMobile} {
      width: calc(100% - 60px);
      margin: 0 30px;
      justify-content: center;
      flex-direction: column;
      // align-items: center;
      gap: 10px;
    }
    @media #{$landscapeSmall} {
      padding: 15px 0;
    }
    a {
      color: rgba($white, .4);
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
}