@import '../../../scss/variables';

body.project {
  #projectHero {
    h1, img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    h1 {
      width: 80%;
      font: 100 6vw/1.1 $font;
      margin: 0;
      text-transform: uppercase;
      white-space: break-spaces;
      @media #{$mobile} {
        font-size: 7vw;
        font-weight: 200;
      }
    }
    img {
      width: 50%;
      height: 50%;
      object-fit: contain;
      @media #{$mobile} {
        width: 80%;
        height: 80%;
      }
    }
  }
  #description {
    background-color: rgba($silver, .3);
    // transform: translate(0, 0) !important;
    #dark {
      background-color: $darkBg;
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      p {
        display: block;
        width: 60%;
        color: $white;
        font: 200 1.5vw/1.5 $font;
        white-space: normal;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media #{$smallDesktop} {
          font-size: 2vw;
        }
        @media #{$mobile} {
          font-size: 2.5vw;
        }
        @media #{$smallMobile} {
          font-size: 3vw;
        }
        @media #{$small400} {
          font-size: 3.5vw;
          font-weight: 300;
          width: 80%;
        }
        @media #{$landscapeSmall} {
          font-size: 2.5vw;
          width: 70%;
        }
      }
    }
  }
  #gallery {
    background-color: $dark;
    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  #projectLink {
    text-align: center;
    a {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font: 200 3vw/1.1 $font;
      color: $dark;
      @media #{$mobile} {
        font-size: 6vw;
      }
      @media #{$mobile} {
        font-size: 10vw;
        font-weight: 300;
      }
    }
  }
}