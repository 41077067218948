@import '../../scss/variables';

body.notFound {
  #heroNotFound {
    h1, h2, a {
      position: absolute;
      top: 50%;
      left: 50%;
      font: 100 3vw/1.1 $font;
      color: $dark;
      display: block;
      margin: 0;
      text-align: center;
      @media #{$smallDesktop} {
        font: 100 4vw/1.1 $font;
      }
      @media #{$mobile} {
        font-weight: 200;
      }
      @media #{$small400} {
        font-size: 6vw;
      }
    }
    a {
      @media #{$desktop} {
        text-decoration: underline;
        text-decoration-thickness: 5px;
        text-decoration-style: wavy;
        text-decoration-color: rgba($dark, .2);
        text-underline-offset: 20px;
      }
      @media #{$smallMobile} {
        font-size: 5vw;
        line-height: 1.5;
      }
      @media #{$small400} {
        font-size: 8vw;
      }
    }
  }
}