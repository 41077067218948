@import '../../scss/variables';
body.work {
  section.content {
    height: auto;
    width: 80%;
    margin: 350px auto 100px;
    @media #{$smallDesktop} {
      margin-top: 250px;
    }
    @media #{$lowHeight} {
      margin-top: 180px;
    }
    @media #{$mobile} {
      margin: 130px auto 50px auto;
      width: calc(100% - 100px);
    }
    @media #{$smallMobile} {
      margin: 100px auto 50px auto;
      width: calc(100% - 60px);
    }
    @media #{$landscapeSmall} {
      margin-top: 110px;
    }
  }
}