@import '../../scss/variables';

body.contact {
  #heroContact {
    h1, h2, a {
      position: absolute;
      top: 50%;
      left: 50%;
      font: 100 3vw/1.1 $font;
      color: $dark;
      display: block;
      margin: 0;
      white-space: nowrap;
      @media #{$smallDesktop} {
        font: 100 4vw/1.1 $font;
      }
      @media #{$mobile} {
        font-weight: 200;
      }
      @media #{$small400} {
        font-size: 6vw;
      }
    }
    a {
      @media #{$smallMobile} {
        font-size: 5vw;
        line-height: 80px;
      }
      @media #{$small400} {
        font-size: 8vw;
      }
    }
  }
  .social {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 100px;
    @media #{$mobile } {
      padding: 100px 50px;
    }
    a {
      img {
        filter: $filterSilver;
      }
    }
  }
}